import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/patientServicesSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            We offer a variety of prosthetic supplies for our patients.
          </h3>
          <p>
            Our goal is to keep our patient&apos;s residual limb healthy and
            ensure a securely fitting prosthesis. Prosthetic Supplies play a
            huge role in maintaining a health fit. Please call us at (601)
            944-1130 for any supply needs!
          </p>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Shrinker Socks
                </ListItem>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Gel Liners
                </ListItem>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Prosthetic Socks
                </ListItem>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Many other Components!
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
