import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import App from "App";

// identify the user.
import { useCookies } from "react-cookie";
import { uuid } from "uuidv4";

const PreApp = () => {
  const [cookies, setCookie] = useCookies(["uuid"]);

  React.useEffect(
    () => {
      if (!cookies.uuid) {
        setCookie("uuid", uuid());
      }
    },
    // eslint-disable-next-line
    []
  );
  return <App />;
};

ReactDOM.render(<PreApp />, document.getElementById("root"));
