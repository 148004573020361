import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useAnalytics } from "use-analytics";
import { useSearchParams } from "react-router-dom";

function useNetlifyFormHandler(name) {
  const { track } = useAnalytics();
  const [error, setError] = useState();
  const [response, setResponse] = useState();
  const [cookies] = useCookies(["uuid", "_fbc", "_fbp"]);
  const [searchParams] = useSearchParams();

  const getAnalyticsIds = () => {
    const eventID = "EVID_" + new Date().valueOf();
    const metadata = {
      fbclid: undefined,
      gclid: undefined,
      fbp: cookies._fbp,
      external_id: cookies.uuid,
      eventID,
    };

    if (searchParams.has("fbclid")) {
      metadata["fbclid"] = searchParams.get("fbclid");
    } else {
      const fbclick = cookies._fbc;
      const fbclick_final = fbclick && fbclick.slice(19);
      metadata["fbclid"] = fbclick_final;
    }
    if (searchParams.has("gclid")) {
      metadata["gclid"] = searchParams.get("gclid");
    }

    return metadata;
  };

  useEffect(() => {
    if (!name || name === "")
      throw new Error("Name is required for Netlify forms");
  });

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }

  const handleSubmit = (data) => {
    const metadata = getAnalyticsIds();
    try {
      const submission = fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": name,
          ...data,
          ...metadata,
        }),
      });
      submission
        .then(function (res) {
          if (!res.ok) {
            throw Error(res.statusText);
          } else {
            track("Lead", {
              formName: name,
              ...data,
              ...metadata,
            });
          }
          return res;
        })
        .then((res) => {
          setResponse(res);
          setError();
        })
        .catch((err) => {
          console.error("Submission error", err);
          setError(err);
        });
      return submission.then(() => true);
    } catch (err) {
      setError(err);
    }
  };
  const formProps = {
    name,
    method: "POST",
    "data-netlify": "true",
  };
  return [formProps, handleSubmit, response, error];
}

export default useNetlifyFormHandler;
