import {
  container,
  title,
  cardTitle,
  grayColor,
  coloredShadow,
} from "assets/jss/material-kit-pro-react.js";

const sectionMoreInfoStyle = {
  container,
  title,
  cardTitle,
  cardLabel: {
    "& > span": {
      verticalAlign: "middle",
      display: "inline-block",
      alignSelf: "center",
    },
  },
  coloredShadow,
  textCenter: {
    textAlign: "center",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
  },
  description: {
    color: grayColor[0],
  },
};

export default sectionMoreInfoStyle;
