import {
  container,
  sectionDark,
  mlAuto,
  mrAuto,
} from "assets/jss/material-kit-pro-react.js";

const videoSection = {
  container,
  mlAuto,
  mrAuto,
  videoSection: {
    paddingBottom: "80px",
  },
  sectionDark: {
    ...sectionDark,
    backgroundSize: "550% 450%",
  },
  video: {
    border: "0",
  },
  textCenter: {
    textAlign: "center",
  },
};

export default videoSection;
