/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import homePageStyle from "assets/jss/material-kit-pro-react/views/homePageStyle.js";

// Sections for this page
import SectionInfo from "./Sections/SectionInfo.js";
import SectionServices from "./Sections/SectionServices.js";
import SectionTeam from "./Sections/SectionTeam.js";
import SectionPreFooter from "./Sections/SectionPreFooter.js";
import SectionPatientSpotlight from "./Sections/SectionSpotlight.js";
import VideoHero from "components/VideoHero/VideoHero.js";

const useStyles = makeStyles(homePageStyle);

export default function HomePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      {/* https://drive.google.com/drive/folders/1qFPhi1l4-LSYt8_H0SNCb0a1-MmKjKUM */}
      <VideoHero url={require("assets/media/hero.mp4")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Your Story Starts With Us.</h1>
              <h4>
                Since we opened our doors in 1992, we have provided
                professional, dependable care. Our patients are proof of our
                work.
              </h4>
              <br />
              <div className={classes.ctas}>
                <Button color="primary" size="lg" href="/get-started">
                  Get Started
                </Button>
                <Button color="secondary" size="lg" href="#our-team">
                  Our Team
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </VideoHero>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionServices />
          <SectionInfo />
          <SectionPatientSpotlight />
          <SectionTeam id="our-team" />
        </div>
      </div>
      <SectionPreFooter />
      {/* BUILD A BLOG USING: -- https://developer.okta.com/blog/2020/02/18/gatsby-react-netlify --> */}
    </div>
  );
}
