import React from "react";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// sections for this page
import SectionText from "./Sections/SectionText.js";
import SectionMoreInfo from "views/PatientServicesPage/Sections/SectionMoreInfo.js";

import patientServicesPageStyle from "assets/jss/material-kit-pro-react/views/patientServicesPageStyle.js";
import GetStartedForm from "views/GetStartedPage/GetStartedForm.js";

const useStyles = makeStyles(patientServicesPageStyle);

export default function OrthoticsPage() {
  const larger = useMediaQuery("(min-width:600px)");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    console.log("is Larger screen", larger);
  });

  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/mural.jpg")}
        filter="primary"
        style={{ height: larger ? "70vh" : "50vh" }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <h1 className={classes.title}>Patient Services / Orthotics</h1>
              <h4 className={classes.subtitle}>
                We strive to provide a comfortable and friendly atmosphere for
                our patients and their families.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <div style={{ paddingTop: "70px" }}>
            <GetStartedForm />
          </div>

          <SectionText />
        </div>
      </div>
      <SectionMoreInfo />
    </div>
  );
}
