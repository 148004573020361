import React from "react";
// nodejs library that concatenates classes

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
// @material-ui/icons
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Quote from "components/Typography/Quote.js";
import InfoArea from "components/InfoArea/InfoArea";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/referralsSections/sectionTextStyle.js";

import rx from "assets/docs/prescription.pdf";
import dbp from "assets/docs/diabetic_patients.pdf";
import faqs from "assets/docs/faqs.pdf";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  const icon1 = () => <i className="fas fa-prescription fa-2x"></i>;
  const icon2 = () => <i className="fas fa-question-circle fa-2x"></i>;

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>Patients Referrals</h3>
          <p>
            Our ABC certified practitioners are ready to help your patients.
            Please utilize the links below for any needed documents. All patient
            referrals may be faxed to (601) 355-7476 (Jackson) or (601) 336-6782
            (Hattiesburg). Our staff will reach out to your patient to schedule
            their free evaluation.
          </p>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="Perscriptions"
            description="Please send this prescription with your referral. You may print this PDF if you do not already have a hard copy."
            action={
              <Button href={rx} color="primary" target="_blank">
                <i className="fas fa-file-pdf"></i>&nbsp;REFERRAL PDF
              </Button>
            }
            icon={LocalHospitalIcon}
            iconColor="info"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="Diabetic Paperwork"
            description="When referring a diabetic patient, please have this paperwork prepared for us to efficiently provide the best care."
            action={
              <Button href={dbp} color="primary" target="_blank">
                <i className="fas fa-file-pdf"></i>&nbsp;DIABETIC PATIENT PDF
              </Button>
            }
            icon={icon1}
            iconColor="success"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <InfoArea
            title="FAQs (Prosthetics)"
            description="This Frequently Asked Questions form provides information about our Prosthetic process and patient care."
            action={
              <Button href={faqs} color="primary" target="_blank">
                <i className="fas fa-file-pdf"></i>&nbsp;PDF
              </Button>
            }
            icon={icon2}
            iconColor="rose"
          />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <Quote
            textClassName={classes.quoteText}
            text="“I left my Doctor's office and went straight to Buckner to make an appointment to be fit for a brace. I was pleasantly surprised they were able to take me as a walk in on that same visit. Great service!”"
            author="Mackie J."
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
