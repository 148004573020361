import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import Subject from "@material-ui/icons/Subject";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Quote from "components/Typography/Quote.js";

import prostheticMolding from "assets/img/prosthetic_molding.jpg";
import patientCare from "assets/img/patient_care.jpg";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/patientServicesSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>Prosthetics</h3>
          <p>
            Buckner Prosthetics specializes in custom crafted Above-Knee, Below-
            Knee, and Upper Limb Prostheses. Our goal is to provide a
            comfortable fitting prosthesis, exceptional patient care, and
            extensive follow-up care to ensure every patient&apos;s success in
            their return to life. We strive to serve each patient and family
            with dignity, respect, and compassion.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“He took the time to explain the entire process, which put us more at ease with the entire situation.”"
            author="Dennis Golladay"
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={10}
          md={10}
          className={classes.section}
          id="prostheticServices"
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <img src={prostheticMolding} alt="..." className={imgClasses} />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <img src={patientCare} alt="..." className={imgClasses} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.titleCenter}>Prosthetic Services</h3>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Pre–Amputation Consultations
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Upper &amp; Lower Extremity Prosthetics
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Microprocessor Controlled System
                </ListItem>
              </List>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Myoelectric Prosthetics
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Geriatric Prosthetics
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Running and Sports Prosthetics
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={8} md={8}>
          <p className={classes.textCenter}>
            Need prosthetic supplies?
            <br />
            <Button href="/prosthetic-supply" color="primary">
              <Subject /> Learn More
            </Button>
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
