import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Payment from "@material-ui/icons/Payment";
import Consultant from "@material-ui/icons/SupervisorAccount";
import Meeting from "@material-ui/icons/MeetingRoom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Primary from "components/Typography/Primary.js";
import Success from "components/Typography/Success.js";
import Info from "components/Typography/Info.js";

import consultations from "assets/img/sections/consultations.jpg";
import walkins from "assets/img/sections/walk-ins.jpg";
import insurance from "assets/img/sections/insurance.jpg";

import sectionMoreInfoStyle from "assets/jss/material-kit-pro-react/views/patientServicesSections/sectionMoreInfoStyle.js";

const useStyles = makeStyles(sectionMoreInfoStyle);

export default function SectionMoreInfo() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              More Information
            </h2>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="/locations">
                      <img src={walkins} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + walkins + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Primary>
                      <h6 className={classes.cardLabel}>
                        <span>
                          <Meeting />
                        </span>{" "}
                        <span>WALK-INS ACCEPTED</span>
                      </h6>
                    </Primary>
                    <h4 className={classes.cardTitle}>
                      <a href="/locations">
                        We encourage patients to come to our offices...
                      </a>
                    </h4>
                    <p className={classes.description}>
                      ...as soon as their physician sends the prescription.
                      Visitors are encouraged to call ahead to ensure adequate
                      time with our ABC certified Practitioners.
                      <a href="/locations"> Our Locations </a>
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="/contact">
                      <img src={consultations} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + consultations + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Info>
                      <h6 className={classes.cardLabel}>
                        <span>
                          <Consultant />
                        </span>{" "}
                        <span>FREE CONSULTATIONS</span>
                      </h6>
                    </Info>
                    <h4 className={classes.cardTitle}>
                      <a href="/contact">
                        We offer free consultations to first-time patients
                      </a>
                    </h4>
                    <p className={classes.description}>
                      We want to provide the most comfortable, convenient care
                      for our Buckner family, so we do not charge for initial
                      consultations. Schedule a consultation today!
                      <a href="/contact"> Contact Us </a>
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="/contact#insurance">
                      <img src={insurance} alt="Most insurances accepted" />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + insurance + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Success>
                      <h6 className={classes.cardLabel}>
                        <span>
                          <Payment />
                        </span>{" "}
                        <span>MOST INSURANCES ACCEPTED</span>
                      </h6>
                    </Success>
                    <h4 className={classes.cardTitle}>
                      <a href="/contact#insurance">
                        We accept all major insurances
                      </a>
                    </h4>
                    <p className={classes.description}>
                      At Buckner Prosthetics, we provide many payment plans and
                      options to help patients easily meet their personal
                      financial needs. Call our office or schedule a visit for
                      further information.
                      <a href="/contact#insurance"> Call Us </a>
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
