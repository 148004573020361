/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Hours from "@material-ui/icons/WatchLater";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// sections for this page
import LocationsLinks, { locations } from "./Sections/LocationsLinks.js";
import SectionTestimonials from "../SharedSections/SectionTestimonials.js";

import locationsStyle from "assets/jss/material-kit-pro-react/views/locationsStyle.js";
import { LocationsMap } from "./Sections/LocationsMap.js";
import allLocations, { locationKeys } from "allLocations.js";

const location = allLocations.find(({ key }) => key == locationKeys.batonRouge);

export const useStyles = makeStyles(locationsStyle);
const center = { lat: 30.399659, lng: -91.102033 };
export default function BatonRougeLocationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.bigMap}>
        <LocationsMap center={center} />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>{location.name}</h2>
            <h5 className={classes.description}>
              Please call to speak with a Buckner Practitioner today.
            </h5>
            <GridContainer>
              <GridItem
                md={4}
                sm={6}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.info}
                  title="Find Us"
                  description={
                    <p>
                      {location.address.map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                      <a href={location.mapLink}>Get directions</a>
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
              </GridItem>
              <GridItem
                md={4}
                sm={6}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.info}
                  title="Reach us"
                  description={
                    <p>
                      Phone:{" "}
                      <a href={`tel:+1 ${location.phone}`}>{location.phone}</a>
                      <br />
                      Fax: {location.fax}
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title={`Office Hours ${location.aptOnly && "*"}`}
                  description={
                    <p>
                      {location.hours.map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                      {location.aptOnly && (
                        <>
                          * <em>By appointment only</em>
                        </>
                      )}
                    </p>
                  }
                  icon={Hours}
                  iconColor="primary"
                />
              </GridItem>
              <GridItem
                md={4}
                sm={12}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.info}
                  icon={DirectionsWalkIcon}
                  title="Free Consultations"
                  description="We offer free consultations to first-time patients. We want to provide the most comfortable, convenient care for our Buckner family, so we do not charge for initial consultations."
                />
              </GridItem>
            </GridContainer>
            <LocationsLinks classes={classes} current={locations.batonRouge} />
          </div>
        </div>
      </div>
      <SectionTestimonials />
    </div>
  );
}
