import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import Explore from "@material-ui/icons/Explore";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionInfo({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features3}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>We would love to meet you</h2>

              <h5 className={classes.description}>
                Schedule an appointment or just come by for a tour of our
                facilities anytime.
              </h5>

              <Button color="primary" round href="/locations/jackson">
                <Explore className={classes.icons} /> Jackson, MS
              </Button>

              <Button color="primary" round href="/locations/hattiesburg">
                <Explore className={classes.icons} /> Hattiesburg, MS
              </Button>
              <br />
              <Button color="primary" round href="/locations/vicksburg">
                <Explore className={classes.icons} /> Vicksburg, MS
              </Button>

              <Button color="primary" round href="/locations/greenwood">
                <Explore className={classes.icons} /> Greenwood, MS
              </Button>
              <br />
              <Button color="primary" round href="/locations/baton-rouge-la">
                <Explore className={classes.icons} /> Baton Rouge, LA
              </Button>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <InfoArea
                className={classes.infoArea}
                icon={DirectionsWalkIcon}
                title="Walk-ins Welcome"
                description="We strive to provide accommodating care. For us to provide timely care, patients are encouraged to call ahead to ensure adequate time with one of our ABC certified Practitioners during our busy clinic."
              />
              <InfoArea
                className={classes.infoArea}
                icon={DirectionsWalkIcon}
                title="Most Insurances Accepted"
                description="We accept all major insurances. We provide many payment plans and options to help patients easily meet their personal financial needs."
              />
              <InfoArea
                className={classes.infoArea}
                icon={DirectionsWalkIcon}
                title="Free Consultations"
                description="All Prosthetics and Orthotic initial evaluations are free. We are happy to give free second opinions and offer advice to previous prosthetics users. Call (601) 944-1130 to schedule yours or a patient's today!"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
