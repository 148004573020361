/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GetStartedForm from "./GetStartedForm";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/getStartedPageStyle.js";

import image from "assets/img/mural.jpg";
const useStyles = makeStyles(loginPageStyle);

export default function GetStartedPage() {
  const [scrolled, setScrolled] = React.useState(false);
  React.useEffect(() => {
    if (!scrolled) {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }
    setScrolled(true);
  }, [scrolled]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <GetStartedForm />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
