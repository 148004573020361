/* eslint-disable react/prop-types */
import React from "react";

// @material-ui/icons
import Explore from "@material-ui/icons/Explore";

// core components
import Button from "components/CustomButtons/Button.js";
import allLocations, { locationKeys } from "allLocations";

export const locations = locationKeys;

export default function LocationsLinks({ classes, current }) {
  return (
    <>
      <h5 className={classes.description}>
        For your convenience, we have {current ? "four other" : "five"}{" "}
        locations.
      </h5>
      {allLocations
        .filter((location) => location.key !== current)
        .map((location) => (
          <Button
            key={location.key}
            color="primary"
            round
            href={`/locations/${location.slug}`}
          >
            <Explore className={classes.icons} /> {location.name}
          </Button>
        ))}
    </>
  );
}
