import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Explore from "@material-ui/icons/Explore";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 9991,
    position: "fixed",
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LocationsAnnouncement() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("showLocationsAnnouncements_2024") === null ||
      localStorage.getItem("showLocationsAnnouncements_2024") === undefined
    )
      setOpen(true);
  }, []);

  const viewedAlert = () => {
    localStorage.setItem("showLocationsAnnouncements_2024", false);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          severity="info"
          action={
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloseIcon />}
              onClick={viewedAlert}
            >
              Close
            </Button>
          }
        >
          <AlertTitle>We have a new location!</AlertTitle>
          <p>
            Buckner Prosthetics Practitioners are now serving Baton Rouge
            Amputees!
          </p>
          <Button
            color="primary"
            round
            href="/locations/baton-rouge-la"
            onClick={viewedAlert}
          >
            <Explore className={classes.icons} /> Baton Rouge, LA
          </Button>
        </Alert>
      </Collapse>
    </div>
  );
}
