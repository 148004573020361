import React from "react";
import { Outlet, useMatch } from "react-router-dom";

// nodejs library to set properties for components
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components

import Header from "components/Header/Header.js";

import HeaderLinks from "components/Header/HeaderLinks.js";

import layoutStyle from "assets/jss/material-kit-pro-react/layoutStyle.js";

// Sections for this layout
import SectionFooter from "./Layout/SectionFooter.js";
import Announcements from "components/Announcements/index.js";

const useStyles = makeStyles(layoutStyle);
export default function Layout({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  let match = useMatch("/locations/*");

  return (
    <div className={classes.layout} {...rest}>
      <Announcements />
      <Header
        color={match ? "primary" : "transparent"}
        brand="Buckner Prosthetics &amp; Orthotics"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "primary",
        }}
      />
      <Outlet />
      <SectionFooter />
    </div>
  );
}
