let fb;
let fbLoaded = false;

export default function facebookPixelPlugin(userConfig = {}) {
  return {
    name: "facebook-ads",
    config: {
      ...userConfig,
    },
    initialize: async ({ config }) => {
      const { pixelId, autoConfig, debug, advancedMatching } = config;
      await import("@bettercart/react-facebook-pixel")
        .then((module) => (fb = module.default))
        .then(() => {
          if (!fbLoaded) {
            fb.init(pixelId, advancedMatching || {}, {
              autoConfig,
              debug,
            });
            console.info("FB Pixel Initialized", config);
            fbLoaded = true;
          }
        })
        .catch((error) => console.error("FB Pixel Init Error", error));
    },
    page: () => {
      fb.pageView();
      console.info("FB Pixel page view");
    },
    /* Track event */
    track: ({ payload }) => {
      const { eventID } = payload.properties;
      if (eventID) {
        fb.track(payload.event, payload.properties, { eventID });
      } else {
        fb.track(payload.event, payload.properties);
      }
      console.info("facebook track", payload);
    },
    /* Identify user */
    identify: () => {
      // I believe FB doesn't have an identify API any more
    },
    loaded: () => {
      return fbLoaded;
    },
  };
}
