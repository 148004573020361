import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionServices({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features1}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>What sets us apart</h2>
              <h5 className={classes.description}>
                Buckner Prosthetics is dedicated to providing top quality
                services to each and every patient. Our state-of-the-art
                facilities can handle all of your Prosthetic and Orthotic needs.
                We strive to provide a comfortable and friendly atmosphere for
                our patients and their families.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={AccessibilityNewIcon}
                title="Prosthetics"
                description="With the most technological advanced prosthetics, we aim to get our patients to a higher level of functionality as quickly as possible."
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={DirectionsWalkIcon}
                title="Orthotics"
                description="Our ABC certified practitioners have extensive experience in orthotic bracing. Each brace is designed for each patient to reach their maximum potential."
                iconColor="primary"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                vertical
                icon={DirectionsRunIcon}
                title="Personalized Care"
                description="Our ABC certified practitioners are skilled in the personalization of each product. We want to make your journey to better health a personal, stress-free experience."
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
