import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";
import Rose from "components/Typography/Rose.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import fastFriends from "assets/img/fast-friends.jpg";
import sign from "assets/img/sign.jpg";

const useStyles = makeStyles(styles);

export default function SectionSpotlight() {
  const classes = useStyles();

  return (
    <div className="cd-section">
      <div className={classes.sectionWhite}>
        <div>
          {/* DYNAMIC COLORED SHADOWS START */}
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <Card blog>
                  <CardHeader image>
                    <a
                      href="https://www.youtube.com/channel/UC0RMpUqgTiTPUUYRc-X6KYw"
                      target="blank"
                    >
                      <img src={fastFriends} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${fastFriends})`,
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Info>
                      <h6 className={classes.cardCategory}>PATIENT STORIES</h6>
                    </Info>
                    <h4 className={classes.cardTitle}>
                      <a
                        href="https://www.youtube.com/channel/UC0RMpUqgTiTPUUYRc-X6KYw"
                        target="blank"
                      >
                        Learn About Our Patients And Their Stories
                      </a>
                    </h4>

                    <p className={classes.cardDescription}>
                      Come learn about some of our patients and hear their
                      stories.
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <Card blog>
                  <CardHeader image>
                    <a href="/about">
                      <img src={sign} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: `url(${sign})`,
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Rose>
                      <h6 className={classes.cardCategory}>ABOUT US</h6>
                    </Rose>
                    <h4 className={classes.cardTitle}>
                      <a href="/about">
                        Buckner&apos;s Has A Long History In Mississippi
                      </a>
                    </h4>
                    <p className={classes.cardDescription}>
                      One of the Largest O&amp;P Specialized Private Practices
                      in Mississippi, Buckner&apos;s has been serving patients
                      since 1992
                    </p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          {/* DYNAMIC COLORED SHADOWS END */}
        </div>
      </div>
    </div>
  );
}
