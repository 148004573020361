import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Subject from "@material-ui/icons/Subject";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Quote from "components/Typography/Quote.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card";

import patientCare from "assets/img/patient_care.jpg";
import patientOrthotics from "assets/img/patient_orthotics.jpg";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/patientServicesSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>Our Patients Are Our Family</h3>
          <p>
            This is the paragraph where you can write more details about your
            services. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more. We are here to make life better.
          </p>
          <Quote
            textClassName={classes.quoteText}
            text="“He took the time to explain the entire process, which put us more at ease with the entire situation.”"
            author="Dennis Golladay"
          />
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card blog plain>
                <CardHeader image plain>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img src={patientCare} alt="..." />
                    <div className={classes.cardTitleAbsolute}>Prosthetics</div>
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${patientCare})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <div className={classes.cardDescription}>
                    About prosthetics patient services
                  </div>
                  <br />
                  <Button href="/prosthetics" color="primary">
                    <Subject /> Learn More
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card blog plain>
                <CardHeader image plain>
                  <a href="/orthotics">
                    <img src={patientOrthotics} alt="..." />
                    <div className={classes.cardTitleAbsolute}>Orthotics</div>
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: `url(${patientOrthotics})`,
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody plain>
                  <div className={classes.cardDescription}>
                    About orthotics patient services
                  </div>
                  <br />
                  <Button href="/orthotics" color="primary">
                    <Subject /> Learn More
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
