import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import LabelImportantIcon from "@material-ui/icons/LabelImportant";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Quote from "components/Typography/Quote.js";

import patientOrthotics from "assets/img/patient_orthotics.jpg";
import patientWorkroom from "assets/img/patient_workroom.jpg";

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/patientServicesSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>Orthotics</h3>
          <p>
            Orthoses are braces/devices designed to support, correct alignment,
            and protect body structures. Buckner Prosthetics offers custom and
            off the shelf orthoses for a number of conditions. We will work
            closely with your Physician or provider, Physical Therapist, and
            nurse to ensure your device is meeting your needs.
          </p>
          <h4 className={classes.title}>
            Common Diagnoses that may require Orthotic Intervention:
          </h4>
          <ul>
            <li>Posterior Tibial Tendon Dysfunction</li>
            <li>Achilles Tendonitis</li>
            <li>Charcot Foot</li>
            <li>Ankle Instability</li>
            <li>Drop Foot</li>
            <li>Arthritis</li>
            <li>Knee/ Hip instability</li>
            <li>Stroke</li>
            <li>Nerve Injuries</li>
            <li>
              Spinal Bracing (Scoliosis, Spondylolisthesis, Compression
              Fractures, etc.)
            </li>
          </ul>
          <Quote
            textClassName={classes.quoteText}
            text="“I have come to Buckner for many years for brace work. The people are great. They are friendly and helpful. The building is clean, spacious, and very nice.”"
            author="Shirley C."
          />
        </GridItem>
        <GridItem
          xs={12}
          sm={10}
          md={10}
          className={classes.section}
          id="orthoticServices"
        >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <img src={patientWorkroom} alt="..." className={imgClasses} />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <img src={patientOrthotics} alt="..." className={imgClasses} />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.titleCenter}>Orthotic Services</h3>
        </GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Upper Limb Orthotics
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" />{" "}
                  &nbsp;Spinal Bracing
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" />{" "}
                  &nbsp;Trauma Bracing
                </ListItem>
              </List>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <List className={classes.list}>
                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Foot, Ankle, Knee &amp; Hip Bracing
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Sports Knee Bracing
                </ListItem>

                <ListItem className={classes.listItem}>
                  <LabelImportantIcon fontSize="small" color="primary" /> &nbsp;
                  Custom Molded Inlays
                </ListItem>
              </List>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
