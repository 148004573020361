/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import Explore from "@material-ui/icons/Explore";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionTestimonials from "views/SharedSections/SectionTestimonials.js";
import SectionVideo from "views/SharedSections/SectionVideo.js";

import locationsStyle from "assets/jss/material-kit-pro-react/views/locationsStyle.js";
import { LocationsMap } from "./Sections/LocationsMap";
import LocationsLinks from "./Sections/LocationsLinks";

const zoom = 6;
const center = { lat: 32.3315409, lng: -90.1326439 };

const useStyles = makeStyles(locationsStyle);

export default function LocationsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.bigMap}>
        <LocationsMap center={center} zoom={zoom} />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Our Locations</h2>

            <LocationsLinks classes={classes} />

            <GridContainer>
              <GridItem
                md={4}
                sm={4}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.infoArea}
                  icon={DirectionsWalkIcon}
                  title="Walk-ins Welcome (Jackson only)"
                  description="We encourage patients to come to our office as soon as their physician sends the prescription. For us to provide timely care, patients are encouraged to call ahead to ensure adequate time with one of our ABC certified Practitioners during our busy clinic. If a better time is avail-able, we will help the patient set up an appointment."
                />
              </GridItem>

              <GridItem
                md={4}
                sm={4}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.infoArea}
                  icon={DirectionsWalkIcon}
                  title="Most Insurances Accepted"
                  description="We accept all major insurances, workers compensation and vocational rehab. At Buckner Prosthetics, we provide many payment plans and options to help patients easily meet their personal financial needs. Call our office or schedule a visit for further information."
                />
              </GridItem>
              <GridItem
                md={4}
                sm={4}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.infoArea}
                  icon={DirectionsWalkIcon}
                  title="Free Consultations"
                  description="We offer free consultations to first-time patients. We want to provide the most comfortable, convenient care for our Buckner family, so we do not charge for initial consultations."
                />
              </GridItem>
            </GridContainer>

            <SectionVideo embedUrl="https://www.youtube.com/embed/LWyTnlZlkUw" />
          </div>
        </div>
      </div>
      <SectionTestimonials />
    </div>
  );
}
