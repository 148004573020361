import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import videoStyle from "assets/jss/material-kit-pro-react/views/videoStyle.js";

const useStyles = makeStyles(videoStyle);

export default function SectionVideo({ embedUrl, ...rest }) {
  const classes = useStyles();
  return (
    <div className={classes.videoSection} {...rest}>
      <div className={classes.video}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <div className={classes.iframeContainer}>
                <iframe
                  height="387"
                  width="100%"
                  src={embedUrl}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
