/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Hours from "@material-ui/icons/WatchLater";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// sections for this page
import LocationsLinks, { locations } from "./Sections/LocationsLinks.js";
import SectionTestimonials from "../SharedSections/SectionTestimonials.js";

import locationsStyle from "assets/jss/material-kit-pro-react/views/locationsStyle.js";
import { LocationsMap } from "./Sections/LocationsMap.js";
import allLocations, { locationKeys } from "allLocations.js";

const location = allLocations.find(({ key }) => key == locationKeys.greenwood);

const center = { lat: 33.5169042, lng: -90.2004599 };

const useStyles = makeStyles(locationsStyle);

export default function GreenwoodLocationsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <div className={classes.bigMap}>
        <LocationsMap center={center} />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>{location.name}</h2>
            <h5 className={classes.description}>
              Schedule an appointment or just come by for a tour of our
              facilities anytime.
            </h5>
            <GridContainer>
              <GridItem
                md={6}
                sm={6}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.info}
                  title="Find Us"
                  description={
                    <p>
                      {location.address.map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                      <a href={location.mapLink}>Get directions</a>
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="primary"
                />
              </GridItem>
              <GridItem
                md={6}
                sm={6}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.info}
                  title="Reach us"
                  description={
                    <p>
                      Phone:{" "}
                      <a href={`tel:+1 ${location.phone}`}>{location.phone}</a>
                      <br />
                      Fax: {location.fax}
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Office Hours"
                  description={
                    <p>
                      {location.hours.map((line) => (
                        <>
                          {line}
                          <br />
                        </>
                      ))}
                    </p>
                  }
                  icon={Hours}
                  iconColor="primary"
                />
              </GridItem>
              <GridItem
                md={4}
                sm={4}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.infoArea}
                  icon={DirectionsWalkIcon}
                  title="Walk-ins Welcome"
                  description="We encourage patients to come to our office as soon as their physician sends the prescription. For us to provide timely care, patients are encouraged to call ahead to ensure adequate time with one of our ABC certified Practitioners during our busy clinic. If a better time is avail-able, we will help the patient set up an appointment."
                />
              </GridItem>
              <GridItem
                md={4}
                sm={4}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.infoArea}
                  icon={DirectionsWalkIcon}
                  title="Most Insurances Accepted"
                  description="We accept all major insurances, workers compensation and vocational rehab. At Buckner Prosthetics, we provide many payment plans and options to help patients easily meet their personal financial needs. Call our office or schedule a visit for further information."
                />
              </GridItem>
              <GridItem
                md={4}
                sm={4}
                className={classes.mlAuto + " " + classes.mrAuto}
              >
                <InfoArea
                  className={classes.infoArea}
                  icon={DirectionsWalkIcon}
                  title="Free Consultations"
                  description="We offer free consultations to first-time patients. We want to provide the most comfortable, convenient care for our Buckner family, so we do not charge for initial consultations."
                />
              </GridItem>
            </GridContainer>
            <LocationsLinks classes={classes} current={locations.greenwod} />
          </div>
        </div>
      </div>
      <SectionTestimonials />
    </div>
  );
}
