import {
  whiteColor,
  grayColor,
  coloredShadow,
  container,
  cardTitle,
  title,
  description,
  hexToRgb,
} from "assets/jss/material-kit-pro-react.js";

import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const sectionTextStyle = {
  container,
  title,
  titleCenter: {
    ...title,
    textAlign: "center !important",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "20px 0 70px",
    "& p": {
      fontSize: "1.188rem",
      lineHeight: "1.5em",
      color: grayColor[15],
      marginBottom: "30px",
    },
    "& $section": {
      paddingBottom: "0",
    },
  },
  quoteText: {
    fontSize: "1.5rem !important",
  },
  textCenter: {
    textAlign: "center",
  },
  ...imagesStyles,

  cardTitleAbsolute: {
    ...cardTitle,
    position: "absolute !important",
    bottom: "15px !important",
    left: "15px !important",
    color: whiteColor + " !important",
    fontSize: "1.125rem !important",
    textShadow:
      "0 2px 5px rgba(" + hexToRgb(grayColor[9]) + ", 0.5) !important",
  },
  cardDescription: {
    ...description,
  },
  coloredShadow,
};

export default sectionTextStyle;
