import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            Since 1992, Martin Buckner opened the doors of Buckner Prosthetics
            with the goal of providing customized care that the people of
            Mississippi needed. Our motto “Technology with a Human Touch” has
            been the focus of our prosthetic and orthotic care from the
            beginning. In 2012, Zachary Myrick, ABC CPO and Stephen Singleton,
            ABP CP acquired Buckner Prosthetics and have continued the tradition
            of excellence. In 2021, Buckner Prosthetics moved into a 9,000
            square foot state-of-the-art clinic with an on site fabrication
            center equipped to better serve our patients. Our team takes great
            pride in listening to our patients feedback, making adjustments, and
            helping them return to the lives they wish to live.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
