/* eslint-disable react/prop-types */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import teamStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/teamStyle.js";

import zachProfile from "assets/img/profiles/card-profile-zack.jpg";
import finProfile from "assets/img/profiles/card-profile-fin.jpg";
import rickProfile from "assets/img/profiles/card-profile-rick.jpg";
import brittanyProfile from "assets/img/profiles/card-profile-brittany.jpg";
import kaitlynProfile from "assets/img/profiles/card-profile-kaitlyn.jpg";
import gatesProfile from "assets/img/profiles/card-profile-gates.jpg";
import walkerProfile from "assets/img/profiles/card-profile-walker.jpg";
import Muted from "components/Typography/Muted";
import CardHeader from "components/Card/CardHeader";

const useStyles = makeStyles(teamStyle);

export default function SectionTeam({
  title = "Meet Our Team",
  slim,
  hide,
  ...rest
}) {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classes.title}>{title}</h2>
          {!slim && (
            <h5 className={classes.description}>
              At Buckner Prosthetics and Orthotics we pride ourselves in
              providing the highest quality of patient care. This requires staff
              that is trained at the highest possible level. The American Board
              of Certification (ABC) represents the highest level of training
              and excelled education in the prosthetic and orthotic field.
            </h5>
          )}
        </GridItem>
      </GridContainer>

      <GridContainer justify="center">
        {hide !== "zach" && (
          <GridItem xs={12} sm={6} md={4}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/zach">
                  <img src={zachProfile} alt="About Zach Myrick" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${zachProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Zachary Myrick</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>ABC, CPO / CO-OWNER</h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {hide !== "fin" && (
          <GridItem xs={12} sm={6} md={4}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/stephen">
                  <img src={finProfile} alt="Stephen Singleton" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${finProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Stephen “Fin” Singleton</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>CP / CO-OWNER</h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      <GridContainer justify="center">
        {hide !== "rick" && (
          <GridItem xs={12} sm={6} md={3}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/rick">
                  <img src={rickProfile} alt="About Rick Psonak" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${rickProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Rick Psonak</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>MS, CPO, FAAOP</h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {hide !== "brittany" && (
          <GridItem xs={12} sm={6} md={3}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/brittany">
                  <img src={brittanyProfile} alt="About Brittany Taylor" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${brittanyProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Brittany Taylor</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>PT, DPT, ATC</h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {hide !== "kaitlyn" && (
          <GridItem xs={12} sm={6} md={3}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/kaitlyn">
                  <img src={kaitlynProfile} alt="About Kaitlyn Caulfield" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${kaitlynProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Kaitlyn Caulfield</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>ABC, CPO</h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {hide !== "gates" && (
          <GridItem xs={12} sm={6} md={3}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/gates">
                  <img src={gatesProfile} alt="About Gates Neuville" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${gatesProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Gates Neuville</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>
                    BOARD ELIGIBLE PRACTITIONER
                  </h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {hide !== "walker" && (
          <GridItem xs={12} sm={6} md={3}>
            <Card plain profile>
              <CardHeader image plain>
                <a href="/about/walker">
                  <img src={walkerProfile} alt="About Walker Foggo" />
                </a>
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${walkerProfile})`,
                    opacity: "1",
                  }}
                />
              </CardHeader>
              <CardBody plain>
                <h4 className={classes.cardTitle}>Walker Foggo</h4>
                <Muted>
                  <h6 className={classes.cardCategory}>
                    ORTHOTIC AND PROSTHETIC RESIDENT
                  </h6>
                </Muted>
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
      {!slim && (
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Office and Technical Staff</h2>
            <h5 className={classes.description}>
              Our full-time office staff will answer any questions you have
              about paperwork, insurance or scheduling. Our technicians, Dan,
              Tripp, Jed, Ricardo, and Matt spend their days fabricating custom
              projects that meet the individual needs of our patients. We take
              great pride in knowing that our patients&apos; devices were built
              in our state of the art fabrication facility by Buckner Trained
              Team Members.
            </h5>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
