import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Star from "@material-ui/icons/Star";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted.js";
import Warning from "components/Typography/Warning.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/testimonialsStyle.js";

const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className={classes.testimonialsSection + " cd-section"} {...rest}>
      {/* Testimonials 2 START */}
      <div
        className={
          classes.testimonials +
          " " +
          classes.sectionDark +
          " " +
          classes.testimonial2
        }
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Carousel {...settings}>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        {'"'}The ladies at the front desk were very helpful and
                        Friendly, Knowledgeable about my needs. Pat was Great
                        when she answered my call. She was very Professional,
                        Friendly Helpful. Dr. Gates was also very Professional,
                        Friendly, Knowledgeable about my Back Brace. Very
                        Efficient. Yes I would Recommend Buckner Prosthetics to
                        Family Members &amp; Friends.{'"'}{" "}
                      </h5>
                      <h4 className={classes.cardTitle}>Darlene B.</h4>
                      <Muted>
                        <h6>Local Guide</h6>
                      </Muted>
                    </CardBody>
                    <div>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                    </div>
                    <Button
                      color="google"
                      simple
                      href="https://g.co/kgs/cFPJNF"
                    >
                      <i className="fab fa-google-plus-square" /> see review
                    </Button>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        {'"'}Buckner Prosthetics has gone above and beyond any
                        expectations I had during my prosthetic journey. Zach
                        and Steven have led me on this journey with great
                        empathy during one of the hardest times of my life. The
                        techs that support them are just as dedicated to getting
                        me going. No one at Buckner has ever given up on
                        insuring the most quality and comfortable prosthetic for
                        my needs.{'"'}{" "}
                      </h5>
                      <h4 className={classes.cardTitle}>Shawn S.</h4>
                    </CardBody>
                    <div>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                    </div>
                    <Button
                      color="google"
                      simple
                      href="https://g.co/kgs/vkXhxu"
                    >
                      <i className="fab fa-google-plus-square" /> see review
                    </Button>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        {'"'}Very caring, knowledgeable people work here. They
                        have a good ability to listin and make a plan for your
                        needs. They make custom stuff or sell things that will
                        help you. They adjust and train you how to use the
                        devices. I am happy with my purchase of a cervical neck
                        brace, it has improved my quality of life.{'"'}{" "}
                      </h5>
                      <h4 className={classes.cardTitle}>Melissa</h4>
                    </CardBody>
                    <div>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                    </div>
                    <Button
                      color="google"
                      simple
                      href="https://g.co/kgs/3ca49m"
                    >
                      <i className="fab fa-google-plus-square" /> see review
                    </Button>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        {'"'}Buckner's and staff has given me the reason to
                        smile and accept everything life has dealt me as a left
                        below the knee amputee. Their staff exemplifies every
                        aspect of professionalism. Any time it is not a fit or
                        need an adjustment they are there ready and willing to
                        make it a perfect fit. Their "Hands On Technology" goes
                        without having to say anything. One touch from them is
                        all it takes.{'"'}{" "}
                      </h5>
                      <h4 className={classes.cardTitle}>Jacqueline S.</h4>
                      <Muted>
                        <h6>Local Guide</h6>
                      </Muted>
                    </CardBody>
                    <div>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                    </div>
                    <Button
                      color="google"
                      simple
                      href="https://g.co/kgs/MFVAEg"
                    >
                      <i className="fab fa-google-plus-square" /> see review
                    </Button>
                  </Card>
                </div>
                <div>
                  <Card testimonial plain className={classes.card2}>
                    <CardBody plain>
                      <h5 className={classes.cardDescription}>
                        {'"'}The staff was very friendly and professional. They
                        had me an appointment the next day. Check in was great
                        at front desk! Dan did. Great job listening to my
                        problem and trying solutions. The facility is clean and
                        very up to date. I was very Pleased with the Service!
                        {'"'}
                      </h5>
                      <h4 className={classes.cardTitle}>Charles K.</h4>
                    </CardBody>
                    <div>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                      <Warning>
                        <Star className={classes.starIcons} />
                      </Warning>
                    </div>
                    <Button
                      color="google"
                      simple
                      href="https://g.co/kgs/GxrrB5"
                    >
                      <i className="fab fa-google-plus-square" /> see review
                    </Button>
                  </Card>
                </div>
              </Carousel>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Testimonials 2 END */}
    </div>
  );
}
