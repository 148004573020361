import React from "react";
// nodejs library to set properties for components
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// @material-ui/icons
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// sections for this page
import SectionText from "./Sections/SectionText.js";
import SectionTestimonials from "../SharedSections/SectionTestimonials.js";

import referralsPageStyle from "assets/jss/material-kit-pro-react/views/referralsPageStyle.js";

const useStyles = makeStyles(referralsPageStyle);

export default function ReferralsPage() {
  const larger = useMediaQuery("(min-width:600px)");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    console.log("is Larger screen", larger);
  });

  const classes = useStyles();
  return (
    <div>
      <Parallax
        image={require("assets/img/bg_wall.jpg")}
        filter="primary"
        style={{ height: larger ? "70vh" : "50vh", backgroundPosition: "top" }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <h1 className={classes.title}>Referrals</h1>
              <h4 className={classes.subtitle}>
                We value your trust with your patient&apos;s care.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionText />
        </div>
      </div>
      <SectionTestimonials />
    </div>
  );
}
