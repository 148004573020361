import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons

import bg from "assets/img/mural.jpg";
import styles from "assets/jss/material-kit-pro-react/views/homeSections/preFooter.js";

const useStyles = makeStyles(styles);

export default function SectionPreFooter() {
  const classes = useStyles();
  return (
    <div className={classes.prefooter}>
      {/* Subscribe */}
      <div
        className={classNames(
          classes.subscribeLine,
          classes.subscribeLineImage
        )}
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center center",
        }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={classNames(classes.mlAuto, classes.mrAuto)}
            >
              <div className={classes.textCenter}>
                <h3 className={classes.title}>
                  Ready to start your journey <br />
                  with Buckner Prosthetics &amp; Orthotics?
                </h3>
                <Button
                  color="primary"
                  className={classes.subscribeButton}
                  size="lg"
                  href="/get-started"
                >
                  I&apos;m ready!
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
