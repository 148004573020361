import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/privacyPolicySections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h3 className={classes.title}>
            We use the information we collect or receive:
          </h3>
          <ul>
            <li>
              To send you marketing and promotional communications. We and/or
              our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. You can opt-out of
              our marketing emails and text messages at any time.
            </li>

            <li>
              By participating, you consent to receive text messages sent by an
              automatic telephone dialing system. Consent to these terms is not
              a condition of purchase. Message and data rates may apply. Text
              STOP to cancel.
            </li>

            <li>
              To send administrative information to you. We may use your
              personal information to send you product, service, and new feature
              information and/or information about changes to our terms,
              conditions, and policies.
            </li>

            <li>
              Fulfill and manage your orders. We may use your information to
              fulfill and manage your orders, payments, returns, and exchanges
              made through the Services or Apps.
            </li>

            <li>
              Deliver targeted advertising to you. We may use your information
              to develop and display content and advertising (and work with
              third parties who do so) tailored to your interests and/or
              location and to measure its effectiveness.
            </li>

            <li>
              Request Feedback. We may use your information to request feedback
              and to contact you about your use of our Services or Apps.
            </li>

            <li>
              To enable user-to-user communications. We may use your information
              in order to enable user-to-user communications with each
              user&apos;s consent.
            </li>

            <li>
              To enforce our terms, conditions and policies for Business
              Purposes, Legal Reasons and Contractual.
            </li>

            <li>
              To respond to legal requests and prevent harm. If we receive a
              subpoena or other legal request, we may need to inspect the data
              we hold to determine how to respond.
            </li>

            <li>
              To deliver services to the user. We may use your information to
              provide you with the requested service.
            </li>

            <li>
              To respond to user inquiries/offer support to users. We may use
              your information to respond to your inquiries and solve any
              potential issues you might have with the use of our Services.
            </li>

            <li>
              For other Business Purposes. We may use your information for other
              Business Purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Services or Apps, products,
              marketing and your experience. We may use and store this
              information in aggregated and anonymized form so that it is not
              associated with individual end users and does not include personal
              information. We will not use identifiable personal information
              without your consent.
            </li>
          </ul>
          <p>
            You may contact us with respect to any complaints, comments or
            concerns regarding our terms and conditions and privacy policy at{" "}
            <a href="mailto:info@bucknerprosthetics.com">
              info@bucknerprosthetics.com
            </a>
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
}
