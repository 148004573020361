/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Quote from "components/Typography/Quote";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Badge from "components/Badge/Badge.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import christian from "assets/img/faces/avatar.jpg";

import zach from "assets/img/faces/zachmyrick.jpg";
import fin from "assets/img/faces/stephensingleton.jpg";

import rick from "assets/img/faces/rickpsonak.jpg";
import brittany from "assets/img/faces/brittanytaylor.jpg";
import kaitlyn from "assets/img/faces/kaitlyncaufield.jpg";
import gates from "assets/img/faces/gates.jpg";
import walker from "assets/img/faces/walker.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";
import SectionSpotlight from "views/HomePage/Sections/SectionSpotlight";
import SectionTeam from "./Sections/SectionTeam";

const useStyles = makeStyles(profilePageStyle);

const bios = {
  zach: {
    name: "Zach Myrick",
    title: "ABC, CPO / CO-OWNER",
    bio: (
      <p>
        Zach is certified in both orthotics and prosthetics through the American
        Board for Certification in Orthotics and Prosthetics. He graduated from
        the University of Southern Mississippi with a Bachelor of Science degree
        in Exercise Science before attending a post graduate Orthotic and
        Prosthetic program in Southern California. He gained valuable experience
        as an Orthotic Resident in Fresno, California and diversified his
        experience in Birmingham, Alabama during his Prosthetic residency. Zach
        is certified in major lower extremity technologies in the O&P industry
        and has lectured on various O&P topics to university students and health
        care providers. He is particularly proud of his craftsmanship and enjoys
        providing the personal care that each case at Buckner Prosthetics
        requires.
      </p>
    ),
    photo: zach,
    focus: [
      "Prosthetics",
      "Orthotics",
      "Microprocessors",
      "Lower Limb",
      "Upper Limb",
      "Spinal Bracing",
    ],
    review: {
      text: `“Buckner Prosthetics has gone above and beyond any expectations I had
            during my prosthetic journey. Zach and Steven have led me on this
            journey with great empathy during one of the hardest times of my
            life. The techs that support them are just as dedicated to getting
            me going. No one at Buckner has ever given up on insuring the most
            quality and comfortable prosthetic for my needs.”`,
      author: "Shawn S.",
    },
  },
  fin: {
    name: "Stephen “Fin” Singleton",
    title: "ABC, CP / CO-OWNER",
    bio: (
      <p>
        Fin is certified in prosthetics through the American Board for
        Certification in Orthotics and Prosthetics. He earned his Bachelor of
        Science in Business Administration from the University of Southern
        Mississippi. He then attended a post graduate program at Chicago's
        Northwestern University for Prosthetics and performed his prosthetic
        residency at the University of Mississippi Medical Center. Fin has
        engaged in research that tested the benefits of vacuum suspension for
        lower extremity prosthetic systems. He is trained in the latest in
        prosthetic technology and takes great pride in each and every prosthetic
        fitting. Fin enjoys helping individuals overcome physical limitations
        and is committed to being an encouragement to all who enter the doors at
        Buckner Orthotic and Prosthetic Labs.
      </p>
    ),
    photo: fin,
    focus: ["Prosthetics", "Lower Limb", "Upper Limb"],
    review: {
      text: `"Awesome state of the art place. Zac and Stephen “Fin” are at the top of their game. Always going above and beyond to make sure their patients are taken care of."`,
      author: "Philip A.",
    },
  },
  rick: {
    name: "Rick Psonak",
    title: "ABC, MS, CPO, FAAOP",
    bio: (
      <p>
        Rick received his training in Orthotics & Prosthetics at the University
        of Washington (Seattle, WA., 1985). Rick is certified in both Orthotics
        and Prosthetics by the American Board for Certification (ABC). He is a
        Fellow of the American Academy of Orthotists & Prosthetists. He Chaired
        the Lower Limb Society of The Academy and is a member of the Upper Limb
        Society. He is a residency director and served two terms as a
        Commissioner for the National Commission for Orthotics and Prosthetics
        Education (NCOPE, 1996-2002). Rick has authored and co-authored chapters
        in text books and journal articles on upper and lower extremity
        prosthetics. He specializes in microprocessor technology. He is well
        known for his proficiency in designing sockets to be used in conjunction
        with various advanced technologies such as: C-leg/Genium Knees, BIOM
        feet, Utah Arm, Dynamic Arm, as well as Michelangelo and I-limb hands.
      </p>
    ),
    photo: rick,
    focus: [
      "Prosthetics",
      "Orthotics",
      "Microprocessors",
      "Lower Limb",
      "Upper Limb",
    ],
    review: {
      text: `"I contacted Rick on a Sunday and he called me back in just a couple hours that was the first time I had every contacted them about a leg!!!
I’m over joyed with the results I have had from the whole staff! I'm very impressed with Buckner prosthetic!"`,
      author: "Scott D.",
    },
  },
  brittany: {
    name: "Brittany Taylor",
    title: "PT, DPT, ATC",
    bio: (
      <p>
        Brittany is the Director of Education at Buckner Prosthetics. Brittany
        attended The University of Southern Mississippi where she earned a
        Bachelor&apos;s Degree in Athletic Training before going on to Physical
        Therapy School. She received her Doctorate of Physical Therapy from The
        University of Mississippi Medical Center. Brittany enjoys working with
        the amputee population and providing additional outreach services for
        Buckner Prosthetics.
      </p>
    ),
    photo: brittany,
    focus: [
      "Physical Therapy",
      "Patient Outreach",
      "Patient Education",
      "Marketing",
    ],
    review: null,
  },
  kaitlyn: {
    name: "Kaitlyn Caulfield",
    title: "ABC, CPO",
    bio: (
      <p>
        Kaitlyn received a Bachelor of Science in Exercise Science from The
        University of Mississippi. She graduated with a Masters of Science in
        Prosthetics and Orthotics from The University of Pittsburgh. Kaitlyn
        completed her orthotics residency at Medical Center O&P in Maryland
        specializing in pediatrics and a prosthetics residency at Walter Reed
        National Military Medical Center with Medical Center O&P specializing in
        military and high activity prosthetics in Maryland. Kaitlyn sees
        patients at both our Jackson and Hattiesburg locations.
      </p>
    ),
    photo: kaitlyn,
    focus: [
      "Prosthetics",
      "Orthotics",
      "Pediatrics",
      "Lower Limb",
      "Upper Limb",
    ],
    review: {
      text: `"Very nice & kind. She Explained everything that was going, and now we are on the road to fixing my son feet!"`,
      author: "Brittany G.",
    },
  },
  gates: {
    name: "Gates Neuville",
    title: "ABC, BOARD ELIGIBLE PRACTITIONER",
    bio: (
      <p>
        Gates attended the University of Mississippi where he obtained a
        Bachelor of Science in Exercise Science. He received his Master's of
        Orthotics and Prosthetics at Northwestern Feinberg School of Medicine.
        Gates has completed his orthotics and prosthetics residency at Buckner
        Prosthetics with specialized training in spinal orthotics. Gates has a
        special interest in pet prosthetics and the pediatric population. Gates
        enjoys getting to build relationships with his patients and seeing them
        return to an active lifestyle.
      </p>
    ),
    photo: gates,
    focus: [
      "Prosthetics",
      "Orthotics",
      "Pediatrics",
      "Pet Prosthetics",
      "Lower Limb",
    ],
    review: {
      text: `"Gates was very Professional, Friendly, Knowledgeable about my Back Brace. Very Efficient. Yes I would Recommend Buckner Prosthetics to Family Members & Friends."`,
      author: "Darlene B.",
    },
  },
  walker: {
    name: "Walker Foggo",
    title: "ORTHOTIC AND PROSTHETIC RESIDENT",
    bio: (
      <p>
        Walker is a 2017 graduate of Mississippi State University with a
        Bachelor of Science in Clinical Exercise Physiology. Walker will
        graduate with his Master&apos;s of Orthotics and Prosthetics from
        Northwestern Feinberg School of Medicine in March 2024. Walker will then
        begin his residency training with Buckner Prosthetics and Orthotics for
        both orthotics and prosthetics. Walker enjoys being able to work
        hands-on to build devices that improve patient&apos;s lives and play a
        role in their rehabilitation.
      </p>
    ),
    photo: walker,
    focus: ["Prosthetics", "Orthotics"],
    review: null,
  },
};

export default function ProfilePage({ person, ...rest }) {
  const profile = bios[person] || {
    name: "Employee",
    title: "not found",
    bio: "",
    photo: christian,
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div>
      <Parallax
        image={require("assets/img/mural.jpg")}
        filter="primary"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={profile.photo} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>{profile.name}</h3>
                  <h6>{profile.title}</h6>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <div className={classes.profile}>
                <h4 className={classes.title}>Focus</h4>
                {profile.focus.map((focus) => (
                  <Badge key={focus}>{focus}</Badge>
                ))}
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <div className={classNames(classes.description)}>
                {profile.bio}
              </div>
            </GridItem>
          </GridContainer>
          <hr />
          {profile.review && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={8} md={8}>
                <Quote
                  textClassName={classes.quoteText}
                  text={profile.review.text}
                  author={profile.review.author}
                />
              </GridItem>
            </GridContainer>
          )}
          <SectionSpotlight />
          <SectionTeam
            slim={true}
            hide={person}
            title="Meet The Rest Of Our Team"
          />
          <Clearfix />
        </div>
      </div>
    </div>
  );
}
