import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import styles from "assets/jss/material-kit-pro-react/components/videoHeroStyle.js";

const useStyles = makeStyles(styles);

export default function VideoHero(props) {
  const classes = useStyles();
  const { children, filter, style, url } = props;
  const maskClasses = classNames({
    [classes.mask]: true,
    [classes[filter + "Color"]]: filter !== undefined,
  });
  return (
    <div
      className={classes.container}
      style={{
        ...style,
      }}
    >
      <div className={classes.videoHero}>
        <video
          playsinline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
          loading="lazy"
        >
          <source src={url} type="video/mp4" />
        </video>
        <span className={maskClasses}></span>
        {children}
      </div>
    </div>
  );
}

VideoHero.defaultProp = {
  color: "white",
};

VideoHero.propTypes = {
  children: PropTypes.node,
  filter: PropTypes.oneOf([
    "primary",
    "rose",
    "dark",
    "info",
    "success",
    "warning",
    "danger",
  ]),
  style: PropTypes.string,
  url: PropTypes.string,
};
